/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { useCall, mainnet, useDisconnect, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther } from 'viem';
import { bsc } from 'viem/chains';
import { Web3 } from "web3";
import logo from '../assets/logo.png';
import twitter from '../assets/twitter.png';
import discord from '../assets/discord.png';

let ABI = [
    {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "NewDistribution",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "holder",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "share",
                "type": "uint256"
            }
        ],
        "name": "NewHolderAdded",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "holder",
                "type": "address"
            }
        ],
        "name": "NewHolderRemoved",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "address[]",
                "name": "_holders",
                "type": "address[]"
            },
            {
                "internalType": "uint256[]",
                "name": "_shares",
                "type": "uint256[]"
            }
        ],
        "name": "addHolders",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "admins",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "contractBalance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "deleteAllHolders",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "distributeFunds",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256[]",
                "name": "_holderIndex",
                "type": "uint256[]"
            }
        ],
        "name": "removeHolders",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "showHolders",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "showShares",
        "outputs": [
            {
                "internalType": "uint256[]",
                "name": "",
                "type": "uint256[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_admin",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "_status",
                "type": "bool"
            }
        ],
        "name": "updateAdmin",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "stateMutability": "payable",
        "type": "receive"
    }
];

let address = "0x871cDAeBBf72d5b3b10a3B25aDad31d9d53a22e8"; // Executive

let ABI2 = [
    {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "NewDistribution",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "holder",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "share",
                "type": "uint256"
            }
        ],
        "name": "NewHolderAdded",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "holder",
                "type": "address"
            }
        ],
        "name": "NewHolderRemoved",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "address[]",
                "name": "_holders",
                "type": "address[]"
            },
            {
                "internalType": "uint256[]",
                "name": "_shares",
                "type": "uint256[]"
            }
        ],
        "name": "addHolders",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "admins",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "contractBalance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "deleteAllHolders",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "distributeFunds",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256[]",
                "name": "_holderIndex",
                "type": "uint256[]"
            }
        ],
        "name": "removeHolders",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "showHolders",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "showShares",
        "outputs": [
            {
                "internalType": "uint256[]",
                "name": "",
                "type": "uint256[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_admin",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "_status",
                "type": "bool"
            }
        ],
        "name": "updateAdmin",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "stateMutability": "payable",
        "type": "receive"
    }
];
let address2 = "0xA5c1F5BBcF8dec231306216206FA3A13f0b07032"; // Elite

let ABI3 = [
    {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "NewDistribution",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "holder",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "share",
                "type": "uint256"
            }
        ],
        "name": "NewHolderAdded",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "holder",
                "type": "address"
            }
        ],
        "name": "NewHolderRemoved",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "address[]",
                "name": "_holders",
                "type": "address[]"
            },
            {
                "internalType": "uint256[]",
                "name": "_shares",
                "type": "uint256[]"
            }
        ],
        "name": "addHolders",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "admins",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "contractBalance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "deleteAllHolders",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "distributeFunds",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256[]",
                "name": "_holderIndex",
                "type": "uint256[]"
            }
        ],
        "name": "removeHolders",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "showHolders",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "showShares",
        "outputs": [
            {
                "internalType": "uint256[]",
                "name": "",
                "type": "uint256[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_admin",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "_status",
                "type": "bool"
            }
        ],
        "name": "updateAdmin",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "stateMutability": "payable",
        "type": "receive"
    }
];
let address3 = "0x2e3BC33a07fc1d9Aa79156758a9AAf7EFA32a2C5"; // Founders

let gatewayABI = [
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "previousAdmin",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "newAdmin",
                "type": "address"
            }
        ],
        "name": "AdminChanged",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "beacon",
                "type": "address"
            }
        ],
        "name": "BeaconUpgraded",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint8",
                "name": "version",
                "type": "uint8"
            }
        ],
        "name": "Initialized",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "Paused",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "userAddress",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "user_id",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "referrer_id",
                "type": "uint256"
            }
        ],
        "name": "RegisterUser",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "Unpaused",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "implementation",
                "type": "address"
            }
        ],
        "name": "Upgraded",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "ALLOW_WHITELISTS_ONLY",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_referrerAddress",
                "type": "address"
            }
        ],
        "name": "Register",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "USD_RATE",
        "outputs": [
            {
                "internalType": "contract IUSDRATE",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "adminAddress",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_toAddress",
                "type": "address"
            }
        ],
        "name": "emergencyWithdrawAsset",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_tokenAddress",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "_toAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_amount",
                "type": "uint256"
            }
        ],
        "name": "emergencyWithdrawTokens",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_id",
                "type": "uint256"
            }
        ],
        "name": "getAddressById",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_amount",
                "type": "uint256"
            }
        ],
        "name": "getCost",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_userAddress",
                "type": "address"
            }
        ],
        "name": "getIdByAddress",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_userAddress",
                "type": "address"
            }
        ],
        "name": "getReferrerByAddress",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "idToAddress",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_usdRateOracle",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "_adminAddress",
                "type": "address"
            }
        ],
        "name": "initialize",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_userAddress",
                "type": "address"
            }
        ],
        "name": "isUserExists",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "pause",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "paused",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "proxiableUUID",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address[]",
                "name": "addresses",
                "type": "address[]"
            },
            {
                "internalType": "bool",
                "name": "excluded",
                "type": "bool"
            }
        ],
        "name": "setWhitelistAddresses",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bool",
                "name": "_status",
                "type": "bool"
            }
        ],
        "name": "setWhitelistsStatus",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalUsers",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "unpause",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newImplementation",
                "type": "address"
            }
        ],
        "name": "upgradeTo",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newImplementation",
                "type": "address"
            },
            {
                "internalType": "bytes",
                "name": "data",
                "type": "bytes"
            }
        ],
        "name": "upgradeToAndCall",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "users",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "referrer",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "totalReferrals",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_userAddress",
                "type": "address"
            }
        ],
        "name": "viewUser",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "_id",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "_referrer",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_totalReferrals",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "whitelistedAddress",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "stateMutability": "payable",
        "type": "receive"
    }
];

let gatewayAddress = "0x0627b33e1fa4d69f96901e1c0c108f07e25b3628";

let registerABI = [
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_name",
                "type": "string"
            }
        ],
        "name": "register",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256[]",
                "name": "tokenIds1",
                "type": "uint256[]"
            },
            {
                "internalType": "uint256[]",
                "name": "tokenIds2",
                "type": "uint256[]"
            },
            {
                "internalType": "uint256[]",
                "name": "tokenIds3",
                "type": "uint256[]"
            }
        ],
        "name": "transferNFT",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_nftContract1",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "_nftContract2",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "_nftContract3",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "nftContract",
                "type": "address"
            }
        ],
        "name": "getTokenIds",
        "outputs": [
            {
                "internalType": "uint256[]",
                "name": "",
                "type": "uint256[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "getUserInfo",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            },
            {
                "internalType": "uint256[]",
                "name": "",
                "type": "uint256[]"
            },
            {
                "internalType": "uint256[]",
                "name": "",
                "type": "uint256[]"
            },
            {
                "internalType": "uint256[]",
                "name": "",
                "type": "uint256[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "nftContract1",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "nftContract2",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "nftContract3",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
];

let registerAddress = "0x1c17c2E042c23d77Ce23D95951a077A0835112f5"

const tweet = () => {
    window.open("#");
}

const discrd = () => {
    window.open("#");
}

function Leaderboard() {
    const { open } = useWeb3Modal()
    const { chain } = useNetwork()
    const { switchNetwork } = useSwitchNetwork()
    const clientID = "2f8f716d-b303-481b-80ef-febaf341524b";
    const [_addresses, set_addresses] = useState([]);
    const web3 = new Web3("https://go.getblock.io/4c0f4773a44c401ea9d6748bde94d7e9");
    const [_commission, setcommission] = useState(0);
    const [_leaderboardArray, setLeaderboardArray] = useState([]);
    const [commissionData, setSortedCommissionData] = useState([]);
    const [_commissionsArray, setCommissionsArray] = useState([]);
    const [_commissionsReceivedFromH, setCommissionsReceivedFromH] = useState([]);
    const [_totalCommissions, setTotalCommissions] = useState(0);

    const { address: walletAddress } = useAccount({
        async onConnect() {
            handleConnect()
        }
    })

    const publicClient = createPublicClient({
        chain: bsc,
        transport: http()
    })

    async function closeNav() {
        set_navbarOpen(0);
    }

    async function navbarOpen() {
        set_navbarOpen(1);
    }

    var contract = {
        address: address,
        abi: ABI
    }

    var contract2 = {
        address: address2,
        abi: ABI2
    }

    var contract3 = {
        address: address3,
        abi: ABI3
    }

    const gateway = {
        address: gatewayAddress,
        abi: gatewayABI
    }

    const registerContract = {
        address: registerAddress,
        abi: registerABI
    }

    const [_totalSupply, settotalSupply] = useState(0);
    const [statusError, setstatusError] = useState("");
    const [statusLoading, setstatusLoading] = useState("");
    const [_owner, set_owner] = useState("");
    const [myNFTWallet, setmyNFTWallet] = useState(0);
    const [_public_mint_status, set_public_mint_status] = useState("");
    const [_navbarOpen, set_navbarOpen] = useState(0);
    const [_connected, setConnected] = useState(false);
    const [_registerDiv, set_registerDiv] = useState(0);
    const [_dashboardDiv, set_dashboardDiv] = useState(0);
    const [_name, set_name] = useState("");
    const { disconnect } = useDisconnect();
    const [_success1, setSuccessMsg1] = useState("");
    const [_loading1, setLoadingMsg1] = useState("");
    const [_ErrorMsg1, setErrorMsg1] = useState("");
    const [success, setsuccess] = useState("");
    const [nfts, setNfts] = useState([]);
    const [selectedContractIndex, setSelectedContractIndex] = useState(0);
    const [userInfo, setUserInfo] = useState(null);
    const [userNFTs, setUserNFTs] = useState([]);
    const [nft1Collection, setUserInfoForNFT1] = useState([1, 2, 3]);
    const [nft2Collection, setUserInfoForNFT2] = useState([1, 2]);
    const [nft3Collection, setUserInfoForNFT3] = useState([1, 2, 3]);
    const [_viewUser, set_getUser] = useState("");
    const [_totalUsers, set_totalUsers] = useState("");
    const [_getAddressById, set_getAddressById] = useState("");

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Variables to store transaction details
    const [blockNumber, setBlockNumber] = useState([]);
    const [timeStamp, setTimeStamp] = useState([]);
    const [hash, setHash] = useState([]);
    const [from, setFrom] = useState([]);
    const [to, setTo] = useState([]);
    const [value, setValue] = useState([]);
    const [contractAddress, setContractAddress] = useState([]);
    const [input, setInput] = useState([]);
    const [type, setType] = useState([]);
    const [gas, setGas] = useState([]);
    const [gasUsed, setGasUsed] = useState([]);
    const [traceId, setTraceId] = useState([]);
    const [isError, setIsError] = useState([]);
    const [errCode, setErrCode] = useState([]);

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const timestamp = 1718257751000;
    const formattedDate = formatDate(timestamp);

    async function handleConnect() {
        if (chain.id !== 56) {
            switchNetwork(56);
        }

        //await fetchNFTs();
        setConnected(true);
    }

    const { refetch: getTotalUser } = useContractRead({
        ...gateway,
        functionName: 'totalUsers', args: []
    })

    const { refetch: getIdByAddress } = useContractRead({
        ...gateway,
        functionName: 'getIdByAddress', args: [walletAddress]
    })

    useEffect(() => {

        async function fetchData3() {
            var data3 = await getTotalUser();
            set_totalUsers(data3.data);
            console.log("totalUsers" + data3.data);
        }

        async function fetchData4() {
            var data4 = await getIdByAddress();
            set_getAddressById(data4.data);
            console.log("getAddressById" + data4.data);
        }


        const internalTxnsHealEdenGateLeaderboard_1 = async () => {
            try {

                const currentBlockNumber = await web3.eth.getBlockNumber();
                console.log('Current block number:', currentBlockNumber);

                const contract = new web3.eth.Contract(gatewayABI, gatewayAddress);

                let addresses = [];

                // Get total users
                const { data: data3 } = await getTotalUser();
                set_totalUsers(data3.data);
                console.log("totalUsers: " + data3.data);

                const totalUsers = await contract.methods.totalUsers().call();
                console.log("totalUsers_web3 : " + totalUsers);
                const idToAddresses = await contract.methods.idToAddress(3).call();
                console.log("idToAddresses_web3 : " + idToAddresses);
                const viewUser = await contract.methods.viewUser(idToAddresses).call();
                console.log("viewUser : " + viewUser[2]);

                const response = await fetch(`https://api.bscscan.com/api?module=account&action=txlistinternal&address=0x4FDa1CDB0629b615cDb0c3d76397747d186Ab82B&startblock=0&endblock='${currentBlockNumber}'&sort=asc&apikey=CXA4YI66CAW6DAXUU7PHD2J9KD97KDVCZ6`);
                const result = await response.json();
                //console.log("Reading Result1 : " + JSON.stringify(result.result));

                if (response.ok) {
                    setData(result);
                    console.log("Reading Result : " + JSON.stringify(result.result.length));
                    //console.log(result.result[0].blockNumber);

                    let commission = 0;
                    let commissionPerAddressArray = [];


                    for (let x = 0; x < result.result.length; x++) {

                        let timelapse = Number(Date.now()) - Number(result.result[x].timeStamp * 1000);
                        let fromAddress = result.result[x].from;

                        //   console.log("IM IN");
                        // console.log("RESULT :" + result.result);
                        //  console.log("value :" + result.result[x].value);

                        if (timelapse <= 2592000000 && fromAddress == '0x4fda1cdb0629b615cdb0c3d76397747d186ab82b') {
                            let commissionValue = Number(result.result[x].value);
                            let toAddress = result.result[x].to;
                            console.log("commissionValue : " + commissionValue);
                            console.log("toAddress : " + toAddress);
                            commissionPerAddressArray.push([toAddress, commissionValue]);
                        }

                    }


                    calculateAndSortCommissions(commissionPerAddressArray);
                    console.log("commissionPerAddressArray : " + commissionPerAddressArray);


                } else {
                    setError(result.message);
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        const internalTxnsHealEdenGate_2 = async () => {
            try {

                const currentBlockNumber = await web3.eth.getBlockNumber();
                console.log('Current block number:', currentBlockNumber);

                const contract = new web3.eth.Contract(gatewayABI, gatewayAddress);

                let addresses = [];

                // Get total users
                const { data: data3 } = await getTotalUser();
                set_totalUsers(data3.data);
                console.log("totalUsers: " + data3.data);

                const totalUsers = await contract.methods.totalUsers().call();
                console.log("totalUsers_web3 : " + totalUsers);
                const idToAddresses = await contract.methods.idToAddress(3).call();
                console.log("idToAddresses_web3 : " + idToAddresses);
                const viewUser = await contract.methods.viewUser(idToAddresses).call();
                console.log("viewUser : " + viewUser[2]);

                let walletAddress2 = '0x2d18430098f1a9dbaf25b09ea0286b37b25d98d7';

                const response = await fetch(`https://api.bscscan.com/api?module=account&action=txlistinternal&address=${walletAddress2}&startblock=0&endblock='${currentBlockNumber}'&sort=asc&apikey=CXA4YI66CAW6DAXUU7PHD2J9KD97KDVCZ6`);
                const result = await response.json();
                //console.log("Reading Result1 : " + JSON.stringify(result.result));

                if (response.ok) {
                    setData(result);
                    console.log("Reading Result : " + JSON.stringify(result.result.length));
                    //console.log(result.result[0].blockNumber);

                    let commission = 0;
                    let commissionsReceivedFromH = [];
                    let totalCommissions = 0;

                    for (let x = 0; x < result.result.length; x++) {

                        let timelapse = Number(Date.now()) - Number(result.result[x].timeStamp * 1000);
                        let fromAddress = result.result[x].from;


                        //   console.log("IM IN");
                        // console.log("RESULT :" + result.result);
                        //  console.log("value :" + result.result[x].value);

                        if (fromAddress == '0x4fda1cdb0629b615cdb0c3d76397747d186ab82b') {
                            let commissionValue = Number(result.result[x].value);
                            console.log("commissionValue : " + commissionValue);
                            //let timeStamp = formatDate(Number(result.result[x].timeStamp * 1000));
                            //commissionsReceivedFromH.push([timeStamp, commissionValue]);
                            totalCommissions = (Number(commissionValue * 1) + Number(totalCommissions * 1));
                            console.log("totalCommissions1 : " + totalCommissions);
                            commissionsReceivedFromH.push(commissionValue);
                        }

                        setTotalCommissions(totalCommissions);
                    }

                    //setCommissionsReceivedFromH(commissionsReceivedFromH);

                    //console.log("commissionsReceivedFromH : " + commissionsReceivedFromH);

                } else {
                    setError(result.message);
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        const calculateAndSortCommissions = (commissions) => {

            // console.log("commissions :" + commissions);
            const commissionMap = {};

            // Calculate total commissions for each address
            commissions.forEach(([address, commission]) => {
                if (!commissionMap[address]) {
                    commissionMap[address] = 0;
                }
                commissionMap[address] += commission;
            });

            // Convert the commission map to an array and sort it
            const sortedCommissions = Object.entries(commissionMap).sort((a, b) => b[1] - a[1]);

            setSortedCommissionData(sortedCommissions);
            console.log("sortedCommsions : " + sortedCommissions);

        };

        internalTxnsHealEdenGateLeaderboard_1();
        internalTxnsHealEdenGate_2();

        if (_connected) {
        }

    }, [_connected, walletAddress]);

  
	function shortenAddress(walletAddress) {
		try {
			return _connected
				? walletAddress.slice(0, 3) + "..." + walletAddress.slice(-4)
				: "Connect";
		} catch (error) {
			console.log(error);
		}
	}

    async function disconnectWallet() {
		setConnected(false);
		disconnect();
		window.location.reload(true);
	}

    return (
        <div class="allWrap">
            <div class="light">
                {/*<div class="leaderboardMain">
                    <div className="intro2">HealEdenGate Commissions Received</div>

                    <table>
                        <thead>
                            <tr>
                                <th>Index</th>
                                <th>Date</th>
                                <th>Commission Received</th>
                            </tr>
                        </thead>
                        <tbody>
                            {_commissionsReceivedFromH.map((entry, index) => (
                                <tr key={entry[0]}>
                                    <td>{index + 1}</td>
                                    <td>{entry[0]}</td>
                                    <td>{(Number(entry[1]) / 10 ** 18).toFixed(2)} BNB</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div >*/}

                <div className="headers">
                    <div className="headers2">
                        <div className="logo" onClick={() => window.location.href = '/'}>
                            <img className="logoPic" src={logo} />
                        </div>
                        <div className="right">
                            <div className="icons">
                                <div className="socialIcon">
                                    <img onClick={tweet} src={twitter} />
                                </div>
                                <div className="socialIcon">
                                    <img onClick={discrd} src={discord} />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div class="leaderboardMain">
                    <div className="intro2" id="mobTxt">HealEstateHive Leaderboard Last 30 Days</div>
                    <div className="intro2-mob"> HealEstateHive</div>
                    <div className="intro2-mob"> Leaderboard</div>
                    <div className="intro2-mob">Last 30 Days</div>
                    <div className='totalAmount'>My Commissions Received from HealEdenGate: {Number(_totalCommissions / 10 ** 18).toFixed(2)} BNB</div>
                    <table>
                        <thead>
                            <tr>
                                <th>Index</th>
                                <th>Address</th>
                                <th>Referrals</th>
                            </tr>
                        </thead>
                        <tbody>
                            {commissionData.map((entry, index) => (
                                <tr key={entry[0]}>
                                    <td>{index + 1}</td>
                                    <td>{entry[0]}</td>
                                    <td>{(Number(entry[1]) / 10 ** 18).toFixed(2)} BNB</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div >

                <p class="liner"></p>

            </div >
        </div >
    )

}

export default Leaderboard;
